<template>
    <div class="datafeed-content">
        <div class="datafeed-content__center">
            <div class="datafeed-content__center__inner">
                <p class="font-bold">
                    You can register for an account
                    <router-link to="/register" class="base-link">here</router-link> or log in
                    <router-link to="/login" class="base-link">here</router-link>.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Home",
    };
</script>
